
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierLoadHeader from '@/components/carrier/PlanCarrierLoadHeader.vue';
import PlanCarrierLoadSubHeader from '@/components/carrier/PlanCarrierLoadSubHeader.vue';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';
import PlanCarrierLoadDetails from '@/components/carrier/loadDetail/PlanCarrierLoadDetails.vue';
import PlanCarrierLoadStopData from '@/components/carrier/loadDetail/PlanCarrierLoadStopData.vue';
import PlanCarrierLoadBid from '@/components/carrier/_quoting/PlanCarrierLoadBid.vue';
import { useStore } from 'vuex';
import PlanCarrierLoadingFullScreen from '@/components/carrier/PlanCarrierLoadingFullScreen.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import { useRouter } from 'vue-router';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import PlanCarrierTrackingElement from '@/components/carrier/PlanCarrierTrackingElement.vue';
import MySignaturePad from '@/modules/carrier/rateConfirmation/CarrierRateConfirmation.vue';
import { useI18n } from 'vue-i18n';
import ImpersonatingBarCarrier from '@/components/sidebar/ImpersonatingBarCarrier.vue';

interface carrierBase {
  load: any;
  loadingLoad: boolean;
}

export default defineComponent({
  name: 'CarrierLoadAccepted',
  components: {
    PlanButton,
    PlanCarrierLoadHeaderNotification,
    ImpersonatingBarCarrier,
    PlanCarrierLoadStopData,
    PlanCarrierLoadDetails,
    PlanCarrierLoadSubHeader,
    PlanCarrierLoadHeader,
    PlanDefaultCarrierView,
  },
  props: {
    id: { type: String, required: false, default: null },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const translate = useI18n();

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion'];
    });

    const goToLoadAccepted = () => {
      router.push({ name: 'CarrierLoadAccepted', params: { id: props.id } });
    };

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData'];
    });

    const stopsData = computed(() => {
      let result: Array<any> = [];

      loadData.value.stops.forEach((stop: any, index: number) => {
        let toCity = stop.location.city;
        if (index < loadData.value.stops.length - 1) {
          toCity = loadData.value.stops[index + 1].location.city;
        }

        result.push({
          stop: stop,
          toCity: toCity,
        });
      });

      return result;
    });

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const shipperHasSelectedYouNotification = computed(() => {
      return (
        currentUser.value.company?.id === loadData.value.shipment?.assignedCompany?.company?.id &&
        loadData.value.status === 'CONFIRMED'
      );
    });

    const tabs = computed(() => {
      return [
        { label: translate.t('load-info') },
        { label: translate.t('tracking'), quantity: 0 },
        { label: translate.t('documents'), quantity: 0 },
      ];
    });

    const getLoad = async () => {
      await store.dispatch(
        'CarrierStore/getLoadInfoForCarrier',
        store.getters['CarrierStore/getLoadData'].id,
      );
    };

    const goToSignOrder = () => {
      router.push({ name: 'CarrierSignOrder', params: { id: props.id } });
    };

    getLoad();

    return {
      actualRegion,
      currentUser,
      goToLoadAccepted,
      goToSignOrder,
      loadData,
      stopsData,
      shipperHasSelectedYouNotification,
      tabs,
    };
  },
});
