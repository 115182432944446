
import { defineComponent, reactive, toRefs, ref, watch, computed } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface planInputHour {
  hour: number | string,
  hours: Array<number|string>,
  hoursChanged: boolean,
  minHour: number,
  minMinute: number,
  minute: number | string,
  minutes: Array<number|string>,
  minutesChanged: boolean,
  isOpen: boolean,
}

export default defineComponent({
  name: 'PlanInputHour',
  components: { PlanIcon, PlanButton },
  props: {
    mandatory: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: '' },
    preValue: { type: String, required: false, default: '' },
    minValue: { type: String, required: false, default: '' }
  },
  emits: ['change'],
  setup (props, {emit}) {
    const data = reactive<planInputHour>({
      hour: '--',
      hours: [],
      hoursChanged: false,
      minHour: -1,
      minMinute: -1,
      minute: '--',
      minutes: [],
      minutesChanged: false,
      isOpen: false
    })

    const pad = (number: number) => {
      return number < 10 ? '0' + number : number;
    }

    for (let i = 0; i < 24; i++) {
      data.hours.push(pad(i));
    }

    for (let i = 0; i < 60; i++) {
      data.minutes.push(pad(i));
    }

    const change = () => {
      if(data.hour === '--') data.hour = 0;
      const dataHour = Number(data.hour)
      if (dataHour > 23) data.hour = 23
      if (dataHour < 0) data.hour = '00'
      if (data.minHour > dataHour) {
        data.hour = data.minHour
      }
      if (Number(data.hour < 10)) data.hour = '0' + Number(data.hour)

      if(data.minute === '--') data.minute = 0;
      const dataMinute = Number(data.minute)
      if (dataMinute > 59) data.minute = 59
      if (dataMinute < 0) data.minute = '00'
      if (data.minMinute > dataMinute) {
        data.minute = data.minMinute
      }
      if (Number(data.minute < 10)) data.minute = '0' + Number(data.minute)

      if (data.hour !== '--' && data.minute !== '--') emit('change', `${data.hour}:${data.minute}`)
    }

    const changeHour = (value: any) => {
      if(data.minute === '--') data.minute = 0;
      let numericValue = Number(value);
      if (numericValue < 0) numericValue = 0;
      if (numericValue > 23) numericValue = 23;
      data.hour = numericValue.toString().padStart(2, '0');
      if (data.hour !== '--' && data.minute !== '--') change()
    }

    const changeMinute = (value: any) => {
      if(data.hour === '--') data.hour = 0;
      let numericValue = Number(value);
      if (numericValue < 0) numericValue = 0;
      if (numericValue > 23) numericValue = 23;
      data.minute = numericValue.toString().padStart(2, '0');
      if (data.hour !== '--' && data.minute !== '--') change()
    }

    const selectAll = (event: any) => {
      event.target.select()
    }

    watch(() => props.preValue, (newValue, oldValue) => {
      if (newValue.length > 0) {
        data.hour = newValue.split(':')[0]
        data.minute = newValue.split(':')[1]
      }
    })

    watch(() => props.minValue, (newValue, oldValue) => {
      if (newValue.length > 0) {
        data.minHour = Number(newValue.split(':')[0])
        data.minMinute = Number(newValue.split(':')[1])
      }
    })

    if (props.preValue && props.preValue.length > 0) {
      data.hour = props.preValue.split(':')[0]
      data.minute = props.preValue.split(':')[1]
    }

    if (props.minValue.length > 0) {
      data.minHour = Number(props.minValue.split(':')[0])
      data.minMinute = Number(props.minValue.split(':')[1])
    }

    return {
      ...toRefs(data),
      change,
      changeHour,
      changeMinute,
      selectAll
    }
  }
})
