import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-bold text-white" }
const _hoisted_2 = { class: "font-bold text-white" }
const _hoisted_3 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_load_header = _resolveComponent("plan-carrier-load-header")!
  const _component_plan_carrier_load_sub_header = _resolveComponent("plan-carrier-load-sub-header")!
  const _component_plan_carrier_load_header_notification = _resolveComponent("plan-carrier-load-header-notification")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_load_details = _resolveComponent("plan-carrier-load-details")!
  const _component_plan_carrier_load_stop_data = _resolveComponent("plan-carrier-load-stop-data")!
  const _component_ImpersonatingBarCarrier = _resolveComponent("ImpersonatingBarCarrier")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_ctx.loadData !== null)
    ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
        key: 0,
        "show-footer": _ctx.loadData.status === 'QUOTING'
      }, {
        "header-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_header)
        ]),
        "subheader-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_sub_header),
          (!_ctx.loadData.isConsigneeFilled && !_ctx.loadData.shipment?.shipmentAcceptCompleted)
            ? (_openBlock(), _createBlock(_component_plan_carrier_load_header_notification, {
                key: 0,
                type: "prim"
              }, {
                container: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.actualRegion === 'NORTH_AMERICA' ? 'pending-rc-usa' : 'pending-rc-eu')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.loadData.shipment?.shipmentAcceptCompleted && _ctx.loadData.shipment?.assignedCompany?.company?.id === _ctx.currentUser?.company?.id)
            ? (_openBlock(), _createBlock(_component_plan_carrier_load_header_notification, {
                key: 1,
                class: "plan-mt-md"
              }, {
                container: _withCtx(() => [
                  _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t(
                _ctx.actualRegion === 'NORTH_AMERICA'
                  ? 'you-can-sign-rate-confirmation-usa'
                  : 'you-can-sign-rate-confirmation-eu',
              )), 1),
                  _createVNode(_component_plan_button, {
                    onOnClick: _ctx.goToSignOrder,
                    type: "primary-light",
                    label: _ctx.$t(_ctx.actualRegion === 'NORTH_AMERICA' ? 'complete-rc-usa' : 'complete-rc-eu'),
                    "font-size": "18",
                    "full-rounded": ""
                  }, null, 8, ["onOnClick", "label"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_plan_carrier_load_details),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stopsData, (stopData) => {
              return (_openBlock(), _createBlock(_component_plan_carrier_load_stop_data, {
                key: stopData.stop,
                "to-city": stopData.toCity,
                stop: stopData.stop
              }, null, 8, ["to-city", "stop"]))
            }), 128))
          ]),
          _createVNode(_component_ImpersonatingBarCarrier)
        ]),
        _: 1
      }, 8, ["show-footer"]))
    : _createCommentVNode("", true)
}